.login {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 100%),
    url('@/assets/login_bg.png') no-repeat center / cover;
  &Box {
    position: relative;
    width: 408.5px;
    background: #ffffff;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    padding: 24px;
    box-sizing: border-box;
  }
  .logo {
    position: fixed;
    top: 4.17px;
    left: 24.17px;
    width: 41.7px;
  }
  .headerBox {
    margin-bottom: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &Title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
      &Left {
        display: flex;
      }
      .leftIcon {
        cursor: pointer;
      }
    }
    .tip {
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;
      color: var(--color-text-3, #86909C);
    }
  }
}

@use-css-vars: 1;@font-size-body: 12px;@font-size-base: 12px;@font-size: 12px;