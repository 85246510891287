.wrapper {
  position: relative;
  background-color: var(--color-bg-1);
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    color: #86909c;
  }
  .btn {
    color: #2789ed;
    margin-top: 8px;
  }
}

.result {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@use-css-vars: 1;@font-size-body: 12px;@font-size-base: 12px;@font-size: 12px;