.mobilePage {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  position: relative;
  font-size: 0.32rem;
  word-break: break-word;
  :global {
    .scroll-page {
      overflow: auto;
      overscroll-behavior: none; /* 禁用边缘弹性效果 */
      padding-bottom: calc(constant(safe-area-inset-bottom));
      padding-bottom: calc(env(safe-area-inset-bottom));
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
    .arco-table-content-inner {
      overscroll-behavior: none; /* 禁用边缘弹性效果 */
    }

    .arco-table-th {
      font-size: 0.28rem;
    }
    .arco-table-td {
      font-size: 0.28rem;
    }
    .arco-table-th-item {
      padding: 0.18rem 0.32rem;
    }
    .arco-table-td {
      padding: 0.18rem 0.32rem;
    }
    .arco-empty {
      .arco-empty-wrapper .arco-empty-image {
        font-size: 0.96rem;
      }
      .arco-empty-description {
        font-size: 0.28rem;
      }
    }
    .arco-mobile-uploader-list-item-container {
      overflow: hidden;
      .arco-mobile-uploader-list-item-wrapper {
        overflow: hidden;
        .arco-mobile-uploader-list-item-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
  }
}

@use-css-vars: 1;@font-size-body: 12px;@font-size-base: 12px;@font-size: 12px;