@import '@arco-themes/react-arco-pro/index.less';
@import 'nprogress/nprogress.css';

@arcoblue-1: #e8f7ff;
@arcoblue-2: #bfe5fb;
@arcoblue-3: #98d1f8;
@arcoblue-4: #71bbf4;
@arcoblue-5: #4ba4f1;
@arcoblue-6: #2789ed;
@arcoblue-7: #1869c5;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color-bg-1);
}
#root {
  font-size: 12px;
}
svg.arco-icon {
  fill: currentColor;
}
.mt-sm {
  margin-top: 12px;
}
.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.row {
  display: flex;
  flex-direction: row;
}
.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
  overflow: hidden;
}
.arco-modal-wrapper.arco-modal-wrapper-align-center div.arco-modal {
}
.hr-menu-icon-empty {
  width: 15px;
  height: 18px;
  display: inline-block;
}
/** 侧边栏缩小后，悬浮的菜单去掉按钮空间 */

.arco-dropdown-menu-item {
  .hr-menu-icon-empty {
    width: 0;
  }
}

/** description组件  */
.arco-descriptions-row {
  .arco-descriptions-item-label {
    vertical-align: baseline;
    white-space: pre-line;
    word-wrap: break-word;
  }
  .arco-descriptions-item-value {
    vertical-align: baseline;
    white-space: pre-line;
    word-wrap: break-word;
  }
}
#x-loading {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
span.arco-typography-secondary {
  color: var(--color-text-3);
}
/** form tooltip 设置鼠标样式*/
.arco-form-label-item .arco-form-item-tooltip {
  cursor: pointer;
}
.arco-table-no-data {
  padding: 0 !important;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/** 修复chrome旧版本对max-content无效的bug */
//.arco-table-layout-fixed table {
//}
.arco-upload-trigger {
  .arco-upload-trigger-drag {
    padding: 40px 0;
  }
  .arco-upload-trigger-drag-text {
    margin: 0;
  }
}
.arco-form-item-control-children {
  & > .arco-input[readonly]:focus {
    background-color: var(--color-fill-2);
    border: 1px solid transparent;
  }
  & > .arco-input[readonly]:hover {
    background-color: var(--color-fill-2);
  }
}

.arco-tabs-content {
  overflow: visible !important;
  .arco-tabs-content-item {
    overflow: visible !important;
  }
}


@use-css-vars: 1;@font-size-body: 12px;@font-size-base: 12px;@font-size: 12px;