.resendCode {
  color: var(--color-primary-1, #2789ED);
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  &.disabled {
    color: var(--color-primary-5, #98D1F8);
    cursor: not-allowed;
  }
}
@use-css-vars: 1;@font-size-body: 12px;@font-size-base: 12px;@font-size: 12px;